<!--
// nuxt-ui/components/pages/region-home/CurrentSeasonSliderMatches.vue
-->
<script setup lang="ts">
import type { Team } from "~/src/team/domain/models/Team";
import type { NitroFetchOptions } from "nitropack";
import type { MatchCenterData } from "~/src/match/domain/models/Match";
import SliderMatches from "~/nuxt-ui/components/Shared/slider-matches/SliderMatches.vue";
import SliderArrowsBehaviour from "~/nuxt-ui/components/Shared/slider-matches/SliderArrowsBehaviour.vue";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

type Props = {
  sectionTitle?: boolean;
  homeTitle?: boolean;
};

withDefaults(defineProps<Props>(), {
  sectionTitle: false,
  homeTitle: false,
});

// const { getSeasonTurns } = useSeasons();
const { currentSeason, currentSeasonTeams } = useCompetitionStore();
const { teamArrToObjById } = useTeams();
const { isWorldCup } = useRegions();
const { locale } = useI18n();

const teamsObj = ref<Record<number, Team>>({});

const opts: NitroFetchOptions<any> = { query: { lang: locale.value || "es" } };
const { data, error } = await useFetch(
  `/api/v1/competition/seasons/${currentSeason.id}/match-center-data`,
  opts
);

if (error.value) {
  console.error(error.value);
}

const turns: MatchCenterData[] = currentSeason ? data.value : [];

// create team Object with the team ID as key.
teamsObj.value = isWorldCup
  ? await teamArrToObjById(currentSeasonTeams, currentSeason?.id)
  : await teamArrToObjById(currentSeasonTeams);
</script>

<template>
  <div v-if="homeTitle">
    <h2 class="sr-only">{{ $t("matches.banner.title") }}</h2>
  </div>
  <div class="px-4 md:px-6 pb-8" v-if="turns && turns.length && sectionTitle">
    <h2 class="kql-page-section-title">{{ $t("teams.nextMatches.title") }}</h2>
    <hr />
  </div>
  <slider-arrows-behaviour
    slider-container-id="matchesSlider"
    :conditionally-render-if="!!turns && !!turns.length"
    button-left-id="buttonLeft"
    button-right-id="buttonRight"
    :button-scroll-distance="170"
  >
    <slider-matches v-if="turns && turns.length" :phase-turns="turns" :teams="teamsObj"></slider-matches>
  </slider-arrows-behaviour>
</template>

<style scoped></style>
